import i18n from 'i18next';
import LocizeBackend from 'i18next-locize-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';

i18n
  .use(LocizeBackend)
  .use(LanguageDetector)
  .use(reactI18nextModule)
  .init({
    fallbackLng: 'fr',
    appendNamespaceToCIMode: true,
    saveMissing: true,

    // have a common namespace used around the full app
    ns: ['translations-fr'],
    defaultNS: 'translations-fr',

    debug: false,
    keySeparator: '### not used ###', // we use content as keys

    backend: {
      projectId: '4c5837cf-a949-4612-925f-2e65b9bb925b', // <-- replace with your projectId
      apiKey: 'c4c978f8-a883-4ccf-83f4-6dcad55229bf',
      referenceLng: 'fr',
      saveMissing: true
    },

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
      format: function(value, format, lng) {
        if (format === 'uppercase') return value.toUpperCase();
        return value;
      }
    },

    react: {
      wait: true
    }
  });

export default i18n;
