import React, { Component } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/now-ui-dashboard.css?v=1.1.1';
import './assets/scss/my-icons-collection/font/flaticon.css';
import './assets/css/demo.css';
//import './assets/css/animate.css';
import configureStore from './helpers/store';
import './i18next';
import App from './App';

const store = configureStore();

class Init extends Component {
  render() {
    return (
      <Provider store={store}>
        <App />
      </Provider>
    );
  }
}

const renderInit = () => {
  render(<Init key={'init-component'} />, document.getElementById('root'));
};

if (module.hot) {
  module.hot.accept('./index', () => {
    renderInit();
  });
}

renderInit();
