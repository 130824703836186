import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firebaseConnect, isEmpty } from "react-redux-firebase";
import app from "firebase/app";

class CloudMessaging extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: []
    };
    this.messaging = app.messaging();
    this.userToken = "";
    this.listenForNewMessages = null;
  }

  // get firebase messaging service worker
  getFirebaseSW() {
    return new Promise((resolve, reject) => {
      navigator.serviceWorker.getRegistrations().then(registrations => {
        registrations.forEach(v => {
          if (v.active.scriptURL.search("firebase-messaging-sw.js") >= 0) {
            return resolve(v);
          }
        });
        return reject(Error("Services worker not found"));
      });
    });
  }

  componentDidMount() {
    this.getFirebaseSW()
      .then(sw => {
        this.messaging.useServiceWorker(sw);

        if (!isEmpty(this.props.user)) {
          // Add the public key generated from the console here.
          this.messaging.usePublicVapidKey(
            "BLnlmBgb-Qq7Mw0WZJe9SYvIMjeQqhUhGmAqCpt1oChcSRgG1ogjFu6kXyszOR_eELa7-9UtcJKwxYK7H26eViQ"
          );
					this.requestPermission();
					sw.addEventListener("message", (message) => console.log(message));
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  componentWillUnmount() {
    this.listenForNewMessages();
  }

  requestPermission() {
    // request user permission to send notifications
    Notification.requestPermission().then(permission => {
      if (permission === "granted") {
        console.log("Notification permission granted.");
        // TODO(developer): Retrieve an Instance ID token for use with FCM.
        // In many cases once an app has been granted notification permission,
        // it should update its UI reflecting this.
        this.resetUI();
      } else {
        console.log("Unable to get permission to notify.");
      }
		});
		this.startListen();
  }

  sendTokenToServer(currentToken) {
		this.userToken = currentToken;
		console.log(this.userToken)
    if (!this.isTokenSentToServer()) {
      console.log("Sending token to server...");
      // TODO(developer): Send the current token to your server.
      this.setTokenSentToServer(true);
    } else {
      console.log(
        "Token already sent to server so won't send it again " +
          "unless it changes"
      );
    }
    this.startListen();
  }

  isTokenSentToServer() {
    return window.localStorage.getItem("sentToServer") === "1";
  }

  setTokenSentToServer(sent) {
    window.localStorage.setItem("sentToServer", sent ? "1" : "0");
  }

  resetUI() {
    // [START get_token]
    // Get Instance ID token. Initially this makes a network call, once retrieved
    // subsequent calls to getToken will return from cache.
    this.messaging
      .getToken()
      .then(currentToken => {
        if (currentToken) {
          console.log("CurrentToken:", currentToken);
          // dH3AE1vh73Q:APA91bFAhhoVz9R0sqRGG1hHWq1pQkxN847RQJbiij5eNC79_TIxX4IWyYLXr14m2jIXIT5H0i-N0TjMf3WGo_nc1vt8vB2P3Bo1buG_VqCcFdlpe_7GEzllUy5f8n01-xo75PFN433M
          this.sendTokenToServer(currentToken);
          //updateUIForPushEnabled(currentToken);
        } else {
          // Show permission request.
          console.log(
            "No Instance ID token available. Request permission to generate one."
          );
          // Show permission UI.
          //updateUIForPushPermissionRequired();
          this.setTokenSentToServer(false);
        }
      })
      .catch(err => {
        console.log("An error occurred while retrieving token. ", err);
        this.setTokenSentToServer(false);
      });
    // [END get_token]
  }

  deleteToken() {
    // Delete Instance ID token.
    // [START delete_token]
    this.messaging
      .getToken()
      .then(currentToken => {
        this.messaging
          .deleteToken(currentToken)
          .then(() => {
            console.log("Token deleted.");
            this.setTokenSentToServer(false);
            this.resetUI();
          })
          .catch(err => {
            console.log("Unable to delete token. ", err);
          });
      })
      .catch(err => {
        console.log("Error retrieving Instance ID token. ", err);
      });
  }

  startListen() {
    console.log("============== startListen =============");
    this.messaging.onMessage(payload => {
      console.log("Message received. ", payload);
      this.setState(ps => ({
        ...ps,
        messages: [...ps.messages, JSON.stringify(payload, null, 2)]
      }));
    });
  }

  refreshToken() {
    this.messaging.onTokenRefresh(() => {
      this.messaging
        .getToken()
        .then(refreshedToken => {
          console.log("Token refreshed.");
          // Indicate that the new Instance ID token has not yet been sent to the
          // app server.
          this.setTokenSentToServer(false);
          // Send Instance ID token to app server.
          this.sendTokenToServer(refreshedToken);
          // [START_EXCLUDE]
          // Display new Instance ID token and clear UI of all previous messages.
          this.resetUI();
          // [END_EXCLUDE]
        })
        .catch(err => {
          console.log("Unable to retrieve refreshed token ", err);
        });
    });
  }

  sendTest() {
    try {
      console.log(this.userToken);
      let appToken = "AIzaSyCcYmICUWHIt8H-nErxiV8RLK_wm4IJuEU";
      let url = "https://fcm.googleapis.com/fcm/send";

      let data = {
        notification: {
          body: "This is an FCM notification message!",
          title: "FCM Message"
        },
        to: this.userToken
      };

      let head = {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: " key=" + appToken
        }),
        body: JSON.stringify(data)
      };

      fetch(url, head)
        .then(response => {
          console.log(response);
        })
        .catch(err => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    console.log(this.state.messages);
    return (
      <div style={{ marginLeft: 100 }}>
        <button onClick={() => this.sendTest()}>Send Notification Test</button>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { user: state.fb.auth, firebase: state.fb };
};

export default compose(
  connect(mapStateToProps),
  firebaseConnect()
)(CloudMessaging);
